/** @jsxImportSource theme-ui */
import { FC, memo } from 'react'
import { Flex } from 'theme-ui'
import _ from 'lodash'
import { PrismicProjectBodySlicesType } from 'root/graphql-types'
import { Body } from 'src/components/atoms/text'
import { Grid } from 'src/components/atoms/layout'
import { xs, ml } from 'src/gatsby-plugin-theme-ui/utils'
import { ResponsiveAsset } from 'src/components/organisms'
import RichText from 'src/components/organisms/RichText'

type ProjectBodyProps = {
  body?: PrismicProjectBodySlicesType[] | null
}

type FlatBody = {
  text?: any
  type?: string
  single_image_desktop?: any
  single_image_mobile?: any
  left_image_desktop?: any
  left_image_mobile?: any
  right_image_desktop?: any
  right_image_mobile?: any
  singleVideoDesktop: any
  singleVideoMobile: any
  leftVideoDesktop: any
  leftVideoMobile: any
  rightVideoDesktop: any
  rightVideoMobile: any
}[]

const ProjectBody: FC<ProjectBodyProps> = ({ body }) => {
  const flatBody = _.flatten(_.map(body, (b) => b.primary)) as FlatBody

  const renderBody = () =>
    _.map(flatBody, (item, i) => {
      const nextItemIsText = flatBody[i + 1] && _.isObject(flatBody[i + 1].text)
      const imageSx = nextItemIsText
        ? { mb: ml('s', 'm') }
        : { mb: ml('l', 'xxl') }

      // (1) Single Image or Video
      if (item?.single_image_desktop || item?.singleVideoDesktop) {
        return (
          <ResponsiveAsset
            sx={imageSx}
            imageDesktop={item?.single_image_desktop}
            imageMobile={item?.single_image_mobile}
            videoDesktop={item?.singleVideoDesktop}
            videoMobile={item?.singleVideoMobile}
            key={i}
          />
        )
      }

      // (2) Left and Right Images or Videos
      else if (
        item?.left_image_desktop ||
        item?.right_image_desktop ||
        item?.leftVideoDesktop ||
        item?.rightVideoDesktop
      ) {
        return (
          <Grid
            key={i}
            sx={imageSx}
            columns={xs(1, 2)}
            gap={['l', 'sGridGap', 'mGridGap', 'lGridGap', 'xlGridGap']}
          >
            <ResponsiveAsset
              sx={{ width: '100%' }}
              imageDesktop={item?.left_image_desktop}
              imageMobile={item?.left_image_mobile}
              videoDesktop={item?.leftVideoDesktop}
              videoMobile={item?.leftVideoMobile}
            />
            <ResponsiveAsset
              sx={{ width: '100%' }}
              imageDesktop={item?.right_image_desktop}
              imageMobile={item?.right_image_mobile}
              videoDesktop={item?.rightVideoDesktop}
              videoMobile={item?.rightVideoMobile}
            />
          </Grid>
        )
      }

      // (3) Text
      else if (item?.text && item?.text?.html) {
        const desktopContentSx = ({
          space,
        }: {
          space: {
            xsGridGap: number
            sGridGap: number
            mGridGap: number
            lGridGap: number
            xlGridGap: number
          }
        }) => {
          const halfPaddings = [
            space.xsGridGap / 2,
            space.sGridGap / 2,
            space.mGridGap / 2,
            space.lGridGap / 2,
            space.xlGridGap / 2,
          ]
          return {
            width: '50%',
            pl: item.type === 'right' ? halfPaddings : 0,
            pr: item.type === 'left' ? halfPaddings : 0,
          }
        }

        const desktopFlexSx = () => {
          if (item.type === 'right') return { justifyContent: 'flex-end' }
          if (item.type === 'left') return { justifyContent: 'flex-start' }

          // item.type === 'center'
          return { justifyContent: 'center' }
        }

        return (
          <div key={i} sx={{ mb: ml('l', 'l2'), mt: 'xl' }}>
            <Body /* Mobile */ sx={{ display: xs('block', 'none'), mb: 0 }}>
              <RichText content={item.text} />
            </Body>
            <Flex /* Desktop */
              sx={{ ...desktopFlexSx(), display: xs('none', 'flex') }}
            >
              <div sx={(theme) => desktopContentSx(theme as any)}>
                <RichText content={item.text} />
              </div>
            </Flex>
          </div>
        )
      }

      // (4) Empty Text
      else if (item?.text) {
        return <div sx={{ pb: ml('64px', 'xl') }} />
      }

      // (5) Invalid
      else {
        console.error('ProjectBody Invalid item', item)
        return null
      }
    })

  return <div>{renderBody()}</div>
}

export default memo(ProjectBody)
