/** @jsxImportSource theme-ui */
import { FC, useRef, useEffect, memo } from 'react'
import { Flex } from 'theme-ui'
// @ts-ignore
import EyesOpen from 'src/svg/eyes_open_right.svg'

const EyesFollowCursor: FC<{ className?: string }> = ({ className }) => {
  const leftEyeRef = useRef<HTMLDivElement>(null)
  const rightEyeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!leftEyeRef?.current || !rightEyeRef?.current) return

    const leftEye = leftEyeRef.current
    const rightEye = rightEyeRef.current

    const onPointerMove = (event: PointerEvent) => {
      const xLeft = leftEye.offsetLeft + leftEye.offsetWidth / 2
      const yLeft = leftEye.offsetTop + leftEye.offsetHeight / 2
      const radLeft = Math.atan2(event.pageX - xLeft, event.pageY - yLeft)
      const rotLeft = radLeft * (180 / Math.PI) * -1 + 180

      const xRight = rightEye.offsetLeft + rightEye.offsetWidth / 2
      const yRight = rightEye.offsetTop + rightEye.offsetHeight / 2
      const radRight = Math.atan2(event.pageX - xRight, event.pageY - yRight)
      const rotRight = radRight * (180 / Math.PI) * -1 + 180

      leftEye.style.transform = `rotate(${rotLeft}deg)`
      rightEye.style.transform = `rotate(${rotRight}deg)`
    }

    document.addEventListener('pointermove', onPointerMove)

    return () => document.removeEventListener('pointermove', onPointerMove)
  }, [])

  const diameter = 16

  return (
    <div className={className}>
      <EyesOpen
        sx={{
          height: 32.5,
          width: 58,
          alignSelf: 'center',
          position: 'absolute',
        }}
      />
      <Flex>
        <div
          sx={{
            mt: '8px',
            ml: '8px',
          }}
        >
          <div
            ref={leftEyeRef}
            sx={{
              width: diameter,
              height: diameter,
              borderRadius: '50%',
            }}
          >
            <div
              sx={{
                bg: 'text',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                transform: `translate3D(${diameter / 2 - 4}px, 0px, 0)`,
              }}
            />
          </div>
        </div>
        <div
          sx={{
            mt: '8px',
            ml: '9px',
          }}
        >
          <div
            ref={rightEyeRef}
            sx={{
              width: diameter,
              height: diameter,
              borderRadius: '50%',
            }}
          >
            <div
              sx={{
                bg: 'text',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                transform: `translate3D(${diameter / 2 - 4}px, 0px, 0)`,
              }}
            />
          </div>
        </div>
      </Flex>
    </div>
  )
}

export default memo(EyesFollowCursor)
