/** @jsxImportSource theme-ui */
import { FC, useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { H6, ListingItem, Body } from 'src/components/atoms/text'
import { replaceHtml } from 'src/utils/htmlReactParser'
import { xs, ml } from 'src/gatsby-plugin-theme-ui/utils'
import { Grid } from 'src/components/atoms/layout'
import { RichText } from 'src/components/organisms'
import linkResolver from 'src/utils/linkResolver'
import { Link } from 'gatsby'

type Content = string | string[] | { html: string; raw?: any }
type Column = { title: string; content: Content }[]

type ProjectDetailsProps = {
  col1: Column
  col2: Column
  sx: any
  show: boolean
}

const renderContent = (content: Content) => {
  if ((content as any)?.[0]?.data?.name) {
    console.log('___ renderContent', content)
    return _.map(content as any, (c, key) => {
      // no link
      if (!c?.data?.landing_page_link?.uid) {
        return <ListingItem key={key}>{c?.data?.name}</ListingItem>
      }

      // with link
      return (
        <Link
          sx={{ textDecoration: 'none' }}
          to={linkResolver(c?.data?.landing_page_link)}
        >
          <ListingItem sx={{ textDecoration: 'underline' }} key={key}>
            {c?.data?.name}
          </ListingItem>
        </Link>
      )
    })
  }

  if (_.isString(content)) return <Body>{content}</Body>

  if (_.isArray(content))
    return _.map(content, (c, key) => <ListingItem key={key}>{c}</ListingItem>)

  if (content?.raw) return <RichText headingsAsSpan content={content} />

  if (content?.html) return replaceHtml(content?.html, 'p', Body)
}

const contentIsEmpty = (content: any) => {
  if (
    _.isEmpty(content) ||
    _.isEqual({ html: '' }, content) ||
    _.isEqual([''], content)
  ) {
    return true
  }

  return false
}

const ProjectDetails: FC<ProjectDetailsProps> = ({
  col1,
  col2,
  show = false,
  ...props
}) => {
  const renderCol = (col: Column) =>
    _.map(col, ({ title, content }, key) => {
      if (contentIsEmpty(content)) return null

      return (
        <div key={key} sx={{ mb: 'l' }}>
          <H6 sx={{ mb: 's' }}>{title}</H6>
          {renderContent(content)}
        </div>
      )
    })

  const [projectDetailsHeight, setProjectDetailsHeight] = useState(0)
  const [translateY, setTranslateY] = useState(0)

  const projectDetailsRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const height = projectDetailsRef?.current?.clientHeight || 0
    if (!show) {
      setProjectDetailsHeight(0)
      setTranslateY(-height)
    } else {
      setProjectDetailsHeight(height)
      setTranslateY(0)
    }
  }, [show])

  return (
    <div
      sx={{
        // pointerEvents: 'none',
        height: `${projectDetailsHeight}px`,
        transition: 'height 1s cubic-bezier(0.07, 0, 0, 0.99)',
        overflow: 'hidden',
      }}
    >
      <div
        sx={{
          transform: `translateY(${translateY}px)`,
          transition: 'transform 1s cubic-bezier(0.07, 0, 0, 0.99)',
        }}
      >
        <div ref={projectDetailsRef} sx={{ pt: ml('l', 'xl') }}>
          <Grid
            columns={xs(1, 2)}
            gap={['0px', 'sGridGap', 'mGridGap', 'lGridGap', 'xlGridGap']}
            {...props}
          >
            <div>{renderCol(col1)}</div>
            <div>{renderCol(col2)}</div>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetails
