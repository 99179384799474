/** @jsxImportSource theme-ui */
import { FC, memo } from 'react'
import _ from 'lodash'
import { Flex } from 'theme-ui'
import { H5Fit } from 'src/components/atoms/text'
import { h4Fit } from 'src/gatsby-plugin-theme-ui/text'
import { Arrow } from 'src/components/atoms/layout'
import { sm } from 'src/gatsby-plugin-theme-ui/utils'
import { fullWidthHeadingSx } from 'src/gatsby-plugin-theme-ui/sizes'
import Sliding from './Sliding'

type ArrowInfoRowProps = {
  title: string
  target: any
  secondRowFixedMobile?: boolean
}
type ArrowInfoRowTextProps = {
  title: string
  target: string
  secondRowFixedMobile?: boolean
}

const ArrowInfoRowDesktop: FC<ArrowInfoRowProps> = ({ title, target }) => {
  return (
    <Flex
      sx={{
        display: sm('none', 'flex'),
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <H5Fit as="span" sx={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
        {title}
      </H5Fit>
      <Arrow sx={{ mx: 'm' }} />
      {target}
    </Flex>
  )
}

const SecondRowMobileMoving: FC<{ target: any }> = ({ target }) => {
  return (
    <Sliding
      speedFactor={50}
      tagName="span"
      sx={{ height: '48px' }}
      direction="LEFT_TO_RIGHT"
    >
      {_.times(8, (key) => {
        if (key % 2 === 0)
          return (
            <span
              key={key}
              sx={{
                display: 'inline-block',
                transform: 'translateY(10px)',
              }}
            >
              {target}
            </span>
          )
        return (
          <div
            key={key}
            sx={{
              display: 'inline-block',
              width: '100px',
              height: '30px',
              mx: 'l',
            }}
          >
            <Arrow />
          </div>
        )
      })}
    </Sliding>
  )
}

const SecondRowMobileFixed: FC<{ target: any }> = ({ target }) => {
  return (
    <Flex
      sx={{
        mx: ['xsGridMargin', 'sGridMargin'],
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Arrow sx={{ mr: 'm' }} />
      {target}
    </Flex>
  )
}

const ArrowInfoRowMobile: FC<ArrowInfoRowProps> = ({
  title,
  target,
  secondRowFixedMobile,
}) => {
  return (
    <Flex /* Mobile */
      sx={{
        ...fullWidthHeadingSx,
        display: sm('flex', 'none'),
        overflow: 'hidden',
        msOverflowStyle: 'none' /* IE and Edge */,
        scrollbarWidth: 'none' /* Firefox */,
        '::-webkit-scrollbar': { display: 'none' },
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Sliding
        speedFactor={75}
        tagName="span"
        sx={{ ...h4Fit, mb: 'xs' }}
        direction="RIGHT_TO_LEFT"
      >
        {_.times(8, (key) => (
          <span key={key} sx={{ pr: 'l' }}>
            {title}
          </span>
        ))}
      </Sliding>
      {secondRowFixedMobile ? (
        <SecondRowMobileFixed target={target} />
      ) : (
        <SecondRowMobileMoving target={target} />
      )}
    </Flex>
  )
}

/**
 * Info row with TITLE -----> TARGET
 */
export const ArrowInfoRow: FC<ArrowInfoRowProps> = memo(
  ({ title, target, secondRowFixedMobile, ...props }) => {
    return (
      <div {...props}>
        <ArrowInfoRowDesktop title={title} target={target} />
        <ArrowInfoRowMobile
          title={title}
          target={target}
          secondRowFixedMobile={secondRowFixedMobile}
        />
      </div>
    )
  }
)

const getTargetHref = (target: string) => {
  if (!_.isString(target)) return undefined

  const emailMatch = target.match(/[A-z]+@[A-z]+\.[A-z]{2,4}/)?.[0]
  if (emailMatch) return `mailto:${emailMatch}`

  const phoneMatch = target.match(/\+49 [0-9 ]+/)?.[0]
  if (phoneMatch) return `tel:${phoneMatch}`
}

/**
 * Info row with TITLE -----> TARGET
 */
export const ArrowInfoRowText: FC<ArrowInfoRowTextProps> = memo(
  ({ title, target, ...props }) => {
    const href = getTargetHref(target)

    const targetJsx = (
      <H5Fit as="span" sx={{ verticalAlign: 'middle', display: 'inline' }}>
        {target}
      </H5Fit>
    )

    if (href)
      return (
        <a sx={{ textDecoration: 'none' }} href={href}>
          <ArrowInfoRow title={title} target={targetJsx} {...props} />
        </a>
      )

    return <ArrowInfoRow title={title} target={targetJsx} {...props} />
  }
)
