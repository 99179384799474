/** @jsxImportSource theme-ui */
import { FC, useState, memo, useMemo, useEffect } from 'react'
import { Flex } from 'theme-ui'
import { useResponsiveValue } from '@theme-ui/match-media'
import _ from 'lodash'
import { motion } from 'framer-motion'
import { ImageProp } from 'src/utils/gatsby'

import { Grid } from 'src/components/atoms/layout'
import { ProjectCard } from 'src/components/organisms'
import {
  PrismicProjectDataType,
  PrismicIndustryDataType,
  PrismicImageType,
} from 'root/graphql-types'
import { H1, H2, H5Fit, BodyLarge10Cols } from 'src/components/atoms/text'
import { ProjectBody, ProjectDetails } from 'src/components/pages/project'
// @ts-ignore
import EyesFollowCursor from 'src/components/molecules/EyesFollowCursor'
// @ts-ignore
import EyesClose from 'src/svg/eyes_close_zzz.svg'
// @ts-ignore
import EyesHover from 'src/svg/eyes_middle.svg'
import { ArrowInfoRow } from 'src/components/molecules'
import { sm, ml } from 'src/gatsby-plugin-theme-ui/utils'
import { ResponsiveAsset } from 'src/components/organisms'
import SEO from 'src/components/organisms/SEO'
import {
  AnimationScrollYFix,
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
  OFFSET_CARDS_Y,
  OFFSET_Y,
} from 'src/animations'
import { RichText, useRichTextCustomStyles } from 'src/components/organisms'

import { setActiveLink } from 'src/components/organisms/NavBar/NavBarDesktop'

type ProjectProps = {
  pageContext: PrismicProjectDataType & {
    seo_image_1200x1200: PrismicImageType
    mainVideoDesktop: any
    mainVideoMobile: any
    flatServices: { name: string; uid: string }[]
    prevProject: any
    nextProject: any
    relatedProjects: any
    alternate_languages: any
    lang: any
    projectPage: {
      data: {
        intro_label: string
        next_button: string
        outcome_label: string
        prev_button: string
        project_details_challenge: string
        project_details_deliverables: string
        project_details_industry: string
        project_details_service: string
        project_details_services: string
        project_details_solution: string
        project_details_title: string
        project_details_year: string
        more_projects_label: string
      }
    }
  }
  path: string
}

const Project: FC<ProjectProps> = (props) => {
  const offsetCard = useResponsiveValue(sm(OFFSET_Y, OFFSET_CARDS_Y))
  const [forceRedrawKey, setForceRedrawKey] = useState(
    'projectpage_not_mounted'
  )

  console.log('___ pageContext', props.pageContext)

  const { all10Cols } = useRichTextCustomStyles()

  const introText = props.pageContext?.intro_text
  const outcomeText = props.pageContext?.outcome_text?.text
  const title = props.pageContext?.main_title
  const client = props.pageContext?.client
  const year = props.pageContext?.year
  const deliverables = props.pageContext?.deliverables
  const challenge = props.pageContext?.challenge || ''
  const solution = props.pageContext?.solution || ''
  const industry = (
    props.pageContext?.industry?.document?.data as PrismicIndustryDataType
  )?.name
  const body = props.pageContext?.body
  const main_image_desktop = props.pageContext?.main_image_desktop
  const main_image_mobile = props.pageContext?.main_image_mobile
  const mainVideoDesktop = props.pageContext?.mainVideoDesktop
  const mainVideoMobile = props.pageContext?.mainVideoMobile
  const labels = props.pageContext?.projectPage?.data
  const services = _.map(props.pageContext?.flatServices, (s) => s.name)
  const [showProjectDetails, setShowProjectDetails] = useState(false)
  const pageContext = props.pageContext
  const path = props.path
  const [eyesHover, setEyesHover] = useState(false)
  const alternate_languages = props.pageContext?.alternate_languages
  const lang = props.pageContext?.lang
  const relatedProjects = props?.pageContext?.relatedProjects

  const hideShowJsx = useMemo(
    () => (
      <Flex sx={{ alignItems: 'center' }}>
        <H5Fit sx={{ verticalAlign: 'middle', cursor: 'pointer' }}>
          {showProjectDetails ? 'Hide' : 'Show'}
        </H5Fit>
        <Flex
          sx={{ flexDirection: 'row', height: '40px' }}
          onPointerEnter={() => setEyesHover(true)}
          onPointerLeave={() => setEyesHover(false)}
        >
          {showProjectDetails ? (
            <EyesFollowCursor
              sx={{
                cursor: 'pointer',
                ml: 's',
                height: 32.5,
                width: 58,
                alignSelf: 'center',
              }}
            />
          ) : eyesHover ? (
            <EyesHover
              sx={{
                cursor: 'pointer',
                ml: 's',
                height: 32.5,
                width: 58,
                alignSelf: 'center',
              }}
            />
          ) : (
            <EyesClose
              sx={{
                cursor: 'pointer',
                ml: 's',
                width: 58,
                transform: 'translateY(-11px)',
                alignSelf: 'center',
              }}
            />
          )}
        </Flex>
      </Flex>
    ),
    [showProjectDetails, eyesHover]
  )

  const mainAssetAspectRatio = (() => {
    if (mainVideoDesktop?.width)
      return mainVideoDesktop.width / mainVideoDesktop.height

    if (main_image_desktop?.dimensions?.width)
      return (
        main_image_desktop.dimensions.width /
        main_image_desktop.dimensions.height
      )

    console.error('Cannot determine aspect ratio for main asset in project')
    return 1
  })()

  setActiveLink('work')
  useEffect(() => setForceRedrawKey('projectpage_mounted'), [])

  console.log('___ services', services)
  console.log('___ flatServices', props?.pageContext?.flatServices)

  return (
    <div key={forceRedrawKey}>
      <SEO
        alternate_languages={alternate_languages}
        lang={lang}
        title={pageContext?.seo_title}
        image={pageContext?.seo_image_1200x1200?.fluid?.src}
        description={pageContext?.seo_description}
        path={path}
      />
      <AnimationScrollYFix />
      <motion.div {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER })}>
        <H1
          sx={{
            textAlign: 'center',
            mt: ml('s', 'l'),
            mb: ml('m', 'l'),
          }}
        >
          {client}
        </H1>
      </motion.div>
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME })}
      >
        <H2 sx={{ textAlign: 'center', mb: ml('m', 'l') }}>{title}</H2>
      </motion.div>
      <motion.div
        {...yOpacityProps({
          delay: DELAY_AFTER_SHUTTER + STAGGER_TIME * 2,
          initialY: offsetCard,
        })}
      >
        <Grid sx={{ mb: ['l', 'm', 'm', 'xl', 'xl'] }}>
          <ResponsiveAsset
            sx={{
              gridColumnStart: mainAssetAspectRatio < 1 ? sm(1, 3) : sm(1, 2),
              gridColumnEnd: mainAssetAspectRatio < 1 ? sm(13, 11) : sm(13, 12),
            }}
            imageDesktop={main_image_desktop as ImageProp}
            videoDesktop={mainVideoDesktop}
            imageMobile={main_image_mobile as ImageProp}
            videoMobile={mainVideoMobile}
          />
        </Grid>
      </motion.div>
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME * 3 })}
      >
        <RichText
          headingsAsSpan
          content={introText}
          sxElements={all10Cols}
          sxContainer={{ mb: ml('xl', 'xxl') }}
        />
        <div onClick={() => setShowProjectDetails(!showProjectDetails)}>
          <ArrowInfoRow
            title="Project Details"
            target={hideShowJsx}
            secondRowFixedMobile={true}
          />
        </div>
        <div
          sx={{
            pb: sm('s', 'm'),
            bg: 'background',
            position: 'relative',
            zIndex: 1000,
          }}
        />
        <ProjectDetails
          show={showProjectDetails}
          sx={({ space }: { space: any }) => ({
            // subtract "l" margin bottom of last paragraph on solution section
            mb: ml(space.l, space.xxl - space.l),
          })}
          col1={[
            { title: labels?.project_details_year, content: year || '' },
            {
              title: labels?.project_details_industry,
              content: industry || '',
            },
            {
              title: labels?.project_details_services,
              content: props?.pageContext?.flatServices || '',
            },
            {
              title: labels?.project_details_deliverables,
              content: _.map(deliverables, (d) => d?.deliverable || ''),
            },
          ]}
          col2={[
            {
              title: labels?.project_details_challenge,
              content: challenge as string,
            },
            {
              title: labels?.project_details_solution,
              content: solution as string,
            },
          ]}
        />
        <div sx={{ mt: ml('l', 'xl') }} />
        <ProjectBody body={body as any} />
        {!!outcomeText && (
          <BodyLarge10Cols sx={{ mb: ml('xl', 'xxl') }}>
            {outcomeText}
          </BodyLarge10Cols>
        )}
        <H2 as="div" sx={{ mb: ml('m2', 'l2') }}>
          {labels.more_projects_label}
        </H2>
        <Grid columns={[1, 2, 3, 3, 3]} sx={{ mb: 'l' }}>
          {_.map(relatedProjects.projects, (project, index) => {
            return (
              <ProjectCard
                key={index}
                aspectRatio={3 / 4}
                imageDesktop={project?.data?.preview_image_desktop}
                imageMobile={project?.data?.preview_image_mobile}
                videoDesktop={project?.data?.previewVideoDesktop}
                videoMobile={project?.data?.previewVideoMobile}
                title={project.data.preview_title}
                client={project.data.client}
                slug={project.uid}
                useCursorRelativeToElementPosition={false}
                mb="l"
              />
            )
          })}
        </Grid>
      </motion.div>
    </div>
  )
}

//export default withPreview(memo(Project))
export default memo(Project)
